import React from "react"

const Presidenta = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 597.5 269">
    <g>
      <path fill="currentColor" d="M52,9.6c5,6,7.5,15.5,7.5,28.5v91c0,13-2.6,22.5-7.7,28.5c-5.2,6-13.4,9-24.7,9h-1v97c0,1.7-0.8,2.5-2.5,2.5
        h-21c-1.7,0-2.5-0.8-2.5-2.5V3.1c0-1.7,0.8-2.5,2.5-2.5H27C38.7,0.6,47,3.6,52,9.6z M32.5,142.8c0.7-1.2,1-2.7,1-4.7
        c0-2,0-3.5,0-4.5V32.1v-3c0-2-0.4-3.6-1.2-4.7c-0.8-1.2-2.6-1.7-5.2-1.7h-1v122h1C30,144.6,31.8,144,32.5,142.8z"/>
      <path fill="currentColor" d="M130.3,262.5c0,2.3-1,3.5-3,3.5h-21c-1.3,0-2.2-0.7-2.5-2c-0.7-3.3-1.2-8.7-1.7-16.2
        c-0.5-7.5-0.7-13.7-0.7-18.7v-77.8v-3.5c0-3-0.3-5.1-1-6.2c-0.7-1.2-2-1.7-4-1.7h-2.5v123.7c0,1.7-0.8,2.5-2.5,2.5h-21
        c-1.7,0-2.5-0.8-2.5-2.5V3.1c0-1.7,0.8-2.5,2.5-2.5h26c11,0,18.9,3,23.7,9c4.8,6,7.2,15.5,7.2,28.5v62.3c0,15.6-5,24.6-15,27v2
        c5.3,0.7,9.1,2.7,11.5,6c2.3,3.3,3.5,9,3.5,17v65.3C127.4,238.2,128.3,253.2,130.3,262.5z M100.6,116.1c0.5-1.5,0.7-4.1,0.7-7.7
        V31.1v-2c0-4.3-1.7-6.5-5-6.5h-2.5v95.7h2.5C98.7,118.3,100.1,117.6,100.6,116.1z"/>
      <path fill="currentColor" d="M162.8,27.1v91.2h11c1.7,0,2.5,0.7,2.5,2v17.5c0,1.3-0.8,2-2.5,2h-11v99.8h11c1.7,0,2.5,0.8,2.5,2.5v21.5
        c0,1.7-0.8,2.5-2.5,2.5h-34.4c-1.7,0-2.5-0.8-2.5-2.5V3.1c0-1.7,0.8-2.5,2.5-2.5h34.4c1.7,0,2.5,0.8,2.5,2.5v21.5
        c0,1.7-0.8,2.5-2.5,2.5H162.8z"/>
      <path fill="currentColor" d="M189.7,259.5c-5-5-7.5-13.6-7.5-26v-82.3c0-1.7,0.8-2.5,2.5-2.5h20.5c1.7,0,2.5,0.8,2.5,2.5v87.3
        c0,2,0.2,3.7,0.7,5c0.5,1.3,1.6,2,3.2,2c1.7,0,2.7-0.7,3.2-2c0.5-1.3,0.7-3,0.7-5v-67.8c0-10-1.2-17.3-3.7-22
        c-2.5-4.7-6.2-8.8-11.2-12.5c-7.3-5.3-12.2-10.6-14.7-15.7c-2.5-5.2-3.7-13.6-3.7-25.2V33.1c0-12.3,2.5-21,7.5-26
        c5-5,12.3-7.5,22-7.5c9.6,0,17,2.5,22,7.5c5,5,7.5,13.6,7.5,26v72.8c0,1.7-0.8,2.5-2.5,2.5h-20.5c-1.7,0-2.5-0.8-2.5-2.5V28.1
        c0-2.3-0.2-4.1-0.7-5.2c-0.5-1.2-1.6-1.7-3.2-1.7c-1.7,0-2.7,0.6-3.2,1.7c-0.5,1.2-0.7,2.9-0.7,5.2v60.8c0,8.3,1.2,14.3,3.5,18
        c2.3,3.7,6.2,7.8,11.5,12.5c7.3,5.7,12.2,11.4,14.7,17.2c2.5,5.8,3.7,15.1,3.7,27.7v69.3c0,12.3-2.5,21-7.5,26
        c-5,5-12.3,7.5-22,7.5C202,267,194.7,264.5,189.7,259.5z"/>
      <path fill="currentColor" d="M251.6,263.5V3.1c0-1.7,0.8-2.5,2.5-2.5h21c1.7,0,2.5,0.8,2.5,2.5v260.4c0,1.7-0.8,2.5-2.5,2.5h-21
        C252.5,266,251.6,265.2,251.6,263.5z"/>
      <path fill="currentColor" d="M340,8.6c5.3,5.3,8,14.5,8,27.5v192.5c0,25-10.3,37.4-30.9,37.4h-26c-1.7,0-2.5-0.8-2.5-2.5V3.1
        c0-1.7,0.8-2.5,2.5-2.5h22.5C325.8,0.6,334.7,3.3,340,8.6z M321.3,242.1c0.5-1.3,0.7-4.3,0.7-9V33.6v-2.5c0-3-0.4-5.1-1.2-6.2
        c-0.8-1.2-2.9-1.9-6.2-2.2v221.5h2.5C319.3,244.1,320.8,243.4,321.3,242.1z"/>
      <path fill="currentColor" d="M383.9,27.1v91.2h11c1.7,0,2.5,0.7,2.5,2v17.5c0,1.3-0.8,2-2.5,2h-11v99.8h11c1.7,0,2.5,0.8,2.5,2.5v21.5
        c0,1.7-0.8,2.5-2.5,2.5h-34.4c-1.7,0-2.5-0.8-2.5-2.5V3.1c0-1.7,0.8-2.5,2.5-2.5h34.4c1.7,0,2.5,0.8,2.5,2.5v21.5
        c0,1.7-0.8,2.5-2.5,2.5H383.9z"/>
      <path fill="currentColor" d="M449.3,3.1c0-1.7,0.8-2.5,2.5-2.5h22c1.7,0,2.5,0.8,2.5,2.5v260.4c0,1.7-0.8,2.5-2.5,2.5h-29.9c-2,0-3-0.7-3-2
        L427.3,41.6H423l6.9,222c0,1.7-0.8,2.5-2.5,2.5h-20c-1.7,0-2.5-0.8-2.5-2.5V3.1c0-1.7,0.8-2.5,2.5-2.5h29.4c1.7,0,2.5,0.7,2.5,2
        l15,227.9h4.5L449.3,3.1z"/>
      <path fill="currentColor" d="M532.7,3.1v21.5c0,1.7-1,2.5-3,2.5h-9.5v236.4c0,1.7-0.8,2.5-2.5,2.5h-20.5c-1.7,0-2.5-0.8-2.5-2.5V27.1h-9.5
        c-2,0-3-0.8-3-2.5V3.1c0-1.7,1-2.5,3-2.5h44.4C531.7,0.6,532.7,1.5,532.7,3.1z"/>
      <path fill="currentColor" d="M530.2,263L542.9,2.6c0.3-1.3,1.2-2,2.5-2h36.4c1.7,0,2.5,0.7,2.5,2L597,263c0,2-0.8,3-2.5,3h-22.8
        c-1.7,0-2.5-0.7-2.5-2l-1-52.4H559l-1,52.4c0,1.4-0.8,2-2.5,2h-22.8C531.4,266,530.2,265.1,530.2,263z M565.9,39.3h-4.5L558.5,186
        h10.3L565.9,39.3z"/>
    </g>
  </svg>
)

export default Presidenta